import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

// CarouselItemContainer
export const CarouselItemContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    padding: '25px 1px',
  },
}));

// ImageWrapper
export const ImageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

// FullscreenImageContainer
export const FullscreenImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '25px 0 50px rgba(0, 0, 0, 0.5)',
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('desktop')]: {
    boxShadow: 'none',
  },
}));

// FullscreenButtonWrapper
export const FullscreenButtonWrapper = styled(Box)({
  position: 'absolute',
  top: '0px',
  right: '15px',
  zIndex: 1,
});

// CarouselItemTextButtonWrapper
export const CarouselItemTextButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '15px 60px',
  textAlign: 'center',
  [theme.breakpoints.down('laptop')]: {
    maxWidth: '100%',
    padding: '15px 5px',
  },
}));

// CarouselItemButtonWrapper
export const CarouselItemButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '15px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    padding: '10px',
  },
}));

// CarouselItemButton
export const CarouselItemButton = styled(Box)({
  paddingRight: '5px',
});

// CarouselItemBottomButton
export const CarouselItemBottomButton = styled(Box)({
  padding: '5px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

// CarouselItemSpan
export const CarouselItemSpan = styled(Typography)(({ theme }) => ({
  padding: '5px',
  color: '#FFFFFF',
  fontSize: '1em',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '0.875em',
  },
}));
