import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import _x10HardwareImageList from "../data/m10HardwareInstallImages";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";

function M10HardwareSetup() {
  const [updateM10HardwareState, m10HardwareState] = InstallStateStore(
    (state) => [state.UpdateM10HardwareState, state.m10HardwareState]
  );
  const navigate = useNavigate();

  function slideChange(swiper: any) {
    if (swiper.activeIndex === _x10HardwareImageList.length - 1) {
      updateM10HardwareState(true);
    } else {
      updateM10HardwareState(false);
    }
  }

  function buttonPress() {
    navigate("/m10-status");
  }

  return (
    <>
      <TheHeader
        headerText={"Install the M10"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/m10-setup-legacy")}
      />
      <ImageCarousel
        imagelist={_x10HardwareImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={m10HardwareState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Next"
      />
    </>
  );
}

export default M10HardwareSetup;
