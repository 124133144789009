export enum Paths {
    s1ArtomeSno = "/s1-artome-sno",
    m10ArtomeSno = "/m10-artome-sno",
    x20ArtomeSno = "/x20-artome-sno",
    userPage = "/user-page",
    s1Setup = "/s1-setup",
    s1Status = "/s1-status",
    s1SetupLegacy = "/s1-setup-legacy",
    m10Setup = "/m10-setup",
    m10Status = "/m10-status",
    m10SetupLegacy = "/m10-setup-legacy",
    x20Setup = "/x20-setup",
    x20Status = "/x20-status",
    signInUp = "/sign-in-up",
    forgotPassword = "/forgot-password",
    s1Tools = "/s1-tools",
    s1Hardware = "/s1-hardware",
    s1Software = "/s1-software",
    s1SoftwareInstall = "/s1-software-install",
    s1LensInstall = "/s1-lens-install",
    s1InstallerStatus = "/s1-status",
    m10Hardware = "/m10-hardware",
    m10Software = "/m10-software",
    m10SoftwareInstall = "/m10-software-install",
    m10LensInstall = "/m10-lens-install",
    m10InstallerStatus = "/m10-status",
    x20Hardware = "/x20-hardware",
    x20Software = "/x20-software",
    x20SoftwareInstall = "/x20-software-install",
    x20LensInstall = "/x20-lens-install",
}
