import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import _x20HardwareImageList from "../data/x20HardwareInstallImages";
import TheHeader from "../components/theHeader";

function X20HardwareInstall() {
  const [UpdateX20HardwareState, x20HardwareState] = InstallStateStore(
    (state) => [state.UpdateX20HardwareState, state.x20HardwareState]
  );
  const navigate = useNavigate();

  function slideChange(swiper: any) {
    if (swiper.activeIndex === _x20HardwareImageList.length - 1) {
      UpdateX20HardwareState(true);
    } else {
      UpdateX20HardwareState(false);
    }
  }

  function buttonPress() {
    navigate("/x20-status");
  }

  return (
    <>
      <TheHeader
        headerText={"Install the X20"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/x20-setup")}
      />
      <ImageCarousel
        imagelist={_x20HardwareImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={x20HardwareState}
        rightButtonPress={(): void => buttonPress()}
        rightButtonTitle="Next"
      />
    </>
  );
}

export default X20HardwareInstall;
