import { ArrayListType } from "../models/types";

import img1 from "../images/S1/install/1.png";
import img2 from "../images/S1/install/2.png";
import img3 from "../images/S1/install/3.png";
import img4 from "../images/S1/install/4.png";
import img5 from "../images/S1/install/5.png";
import img6 from "../images/S1/install/6.png";
import img7 from "../images/S1/install/7.png";
import img8 from "../images/S1/install/8.png";
import img9 from "../images/S1/install/9.png";
import img10 from "../images/S1/install/10.png";
import img11 from "../images/S1/install/11.png";
import img12 from "../images/S1/install/12.png";
import img13 from "../images/S1/install/13.png";
import img14 from "../images/S1/install/14.png";
import img15 from "../images/S1/install/15.png";
import img16 from "../images/S1/install/16.png";
import img17 from "../images/S1/install/17.png";
import img18 from "../images/S1/install/18.png";
import img19 from "../images/S1/install/19.png";

const _s1ImageList: Array<ArrayListType> = [
  {
    image: img1,
    text: "Installation toolkit contents: cable harness, HDMI cable, LAN cable, projector plate, thumb screws, PH2 screws, PH2 tool and HEX3.0 tool. NOTE: The cables might already be in place.",
  },
  {
    image: img2,
    text: "Compatible projectors: EB-775F, EB-770F, EB-760W, EB-735F or EB-725W.",
  },
  {
    image: img3,
    text: "Install projector plate with PH2 screws.",
  },
  {
    image: img4,
    text: "Remove the top cover with HEX3.0 tool.",
  },
  {
    image: img5,
    text: "Remove the glass.",
  },
  {
    image: img6,
    text: "Remove the back cover with HEX3.0 tool.",
  },
  {
    image: img7,
    text: "Mount the projector inside the cabinet.",
  },
  {
    image: img8,
    text: "Fix the projector to cabinet with thumb screws.",
  },
  {
    image: img9,
    text: "Connect cable harness and LAN cable in following order: HDMI 1, LAN cable, Audio in, RS-232, power.",
  },
  {
    image: img10,
    text: "Connect the cable harness and HDMI cable to the projector.",
  },
  {
    image: img11,
    text: "Secure the cable harness and HDMI cable with cable ties.",
  },
  {
    image: img12,
    text: "Connect HDMI2 and LAN cable to the back cover. Then install the back cover.",
  },
  {
    image: img13,
    text: "Install the glass. Clean it if needed.",
  },
  {
    image: img14,
    text: "Install the top cover.",
  },
  {
    image: img15,
    text: "Install back cover.",
  },
  {
    image: img16,
    text: "Connect the power cable. The cable is stored inside the device.",
  },
  {
    image: img17,
    text: "Press power and wait 2 minutes until automatic setup is ready. The device will shut down when ready.",
  },
  {
    image: img18,
    text: "Optionally enable automatic power on for HDMI plug-in. Navigate to Menu > Operation. Set Auto Power On: HDMI1 (and if visible, Power On Trigger: Plug-in-Detection).",
  },
  {
    image: img19,
    text: "Installation is ready.",
  },
];

export default _s1ImageList;
