import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import _x20SoftwareImageList from "../data/X20SoftwareInstallImages";
import TheHeader from "../components/theHeader";

function X20SoftwareInstall() {
  const [UpdateX20SoftwareState, x20SoftwareState] = InstallStateStore(
    (state) => [state.UpdateX20SoftwareState, state.x20SoftwareState]
  );
  const navigate = useNavigate();

  function slideChange(swiper: any) {
    if (swiper.activeIndex === _x20SoftwareImageList.length - 1) {
      UpdateX20SoftwareState(true);
    } else {
      UpdateX20SoftwareState(false);
    }
  }

  function buttonPress() {
    navigate("/x20-status");
  }

  return (
    <>
      <TheHeader
        headerText={"Setup the X20"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/x20-setup")}
      />
      <ImageCarousel
        imagelist={_x20SoftwareImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={x20SoftwareState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Next"
      />
    </>
  );
}

export default X20SoftwareInstall;
