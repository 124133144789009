import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import _x20LensImageList from "../data/x20LensInstallImages";
import TheHeader from "../components/theHeader";

function X20LensInstall() {
  const [UpdateX20LensState, x20LensState] = InstallStateStore((state) => [
    state.UpdateX20LensState,
    state.x20LensState,
  ]);
  const navigate = useNavigate();

  function slideChange(swiper: any) {
    if (swiper.activeIndex === _x20LensImageList.length - 1) {
      UpdateX20LensState(true);
    } else {
      UpdateX20LensState(false);
    }
  }

  function buttonPress() {
    navigate("/x20-status");
  }

  return (
    <>
      <TheHeader
        headerText={"Install the X20 Lens"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/x20-setup")}
      />
      <ImageCarousel
        imagelist={_x20LensImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={x20LensState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Next"
      />
    </>
  );
}

export default X20LensInstall;
