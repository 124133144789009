import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { Index } from "firebase/firestore";
import _x10ImageList from "../data/m10InstallImages";

function M10Setup() {
  const [updateM10HardwareState, m10HardwareState] = InstallStateStore(
    (state) => [state.UpdateM10HardwareState, state.m10HardwareState]
  );
  const navigate = useNavigate();

  function slideChange(swiper: Index) {
    if (swiper.activeIndex === _x10ImageList.length - 1) {
      updateM10HardwareState(true);
    } else {
      updateM10HardwareState(false);
    }
  }

  function buttonPress() {
    navigate("/");
  }

  return (
    <>
      <TheHeader
        headerText={"Install the M10"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <ImageCarousel
        imagelist={_x10ImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={m10HardwareState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Done"
        link="/"
        linkText="test"
      />
    </>
  );
}

export default M10Setup;
