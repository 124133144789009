import React from "react";
import { Box, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const NoProductData: React.FC = () => (
  <Box 
    display="flex" 
    flexDirection="column" 
    alignItems="center" 
    justifyContent="center" 
    height="80vh"
  >
    <SentimentDissatisfiedIcon style={{ fontSize: 100 }} />
    <Typography variant="h4" gutterBottom>
      Oops!
    </Typography>
    <Typography variant="subtitle1">
      No products found.
    </Typography>
  </Box>
);

export default NoProductData;
