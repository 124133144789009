import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserData, logout } from "../API/firebase";
import { useNavigate } from "react-router-dom";
import { UserDataTypes } from "../models/types";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";

import { Edit, Person, Email, Lock, Apartment } from "@mui/icons-material";

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<UserDataTypes | null>(null);

  useEffect(() => {
    getUserData()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <div className="sign-x-container">
      <div className="user-info-box">
        {loading ? (
          <CircularProgress />
        ) : (
          //<div>{data ? data.email : <CircularProgress />}</div>
          <List
            sx={{ width: "100%", maxWidth: 360, color: '#FFFFFF'}}
          >
            <ListItem
              secondaryAction={
                <IconButton aria-label="comment" sx={{ color: '#FFFFFF' }}>
                  <Edit />
                </IconButton>
              }
            >
              <ListItemIcon sx={{ color: '#FFFFFF' }}>
                <Person />
              </ListItemIcon>
              <ListItemText primary={data?.name} secondary="Name" sx={{ color: '#FFFFFF', '& .MuiListItemText-secondary': { color: '#FFFFFF' } }} />
            </ListItem>

            <Divider variant="inset" component="li" sx={{ borderColor: '#FFFFFF' }} />

            <ListItem
              secondaryAction={
                <IconButton aria-label="comment" sx={{ color: '#FFFFFF' }}>
                  <Edit />
                </IconButton>
              }
            >
              <ListItemIcon sx={{ color: '#FFFFFF' }}>
                <Apartment />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFFFFF', '& .MuiListItemText-secondary': { color: '#FFFFFF' } }} primary={data?.company} secondary="Company" />
            </ListItem>

            <Divider variant="inset" component="li" sx={{ borderColor: '#FFFFFF' }} />

            <ListItem>
              <ListItemIcon sx={{ color: '#FFFFFF' }}>
                <Email />
              </ListItemIcon>
              <ListItemText primary={data?.email} secondary="Email" sx={{ color: '#FFFFFF', '& .MuiListItemText-secondary': { color: '#FFFFFF' } }} />
            </ListItem>

            <Divider variant="inset" component="li" sx={{ borderColor: '#FFFFFF' }} />
            <ListItem
              secondaryAction={
                <IconButton aria-label="comment" sx={{ color: '#FFFFFF' }}>
                  <Edit sx={{ color: '#FFFFFF' }} />
                </IconButton>
              }
            >
              <ListItemIcon sx={{ color: '#FFFFFF' }}>
                <Lock sx={{ color: '#FFFFFF' }}/>
              </ListItemIcon>
              <ListItemText primary="**********" secondary="Password" sx={{ color: '#FFFFFF', '& .MuiListItemText-secondary': { color: '#FFFFFF' } }} />
            </ListItem>
          </List>
        )}

        <div className="user-info-buttons-container">
          <Button size="large" onClick={(): void => navigate("/delete-user-account")} variant="contained" color="error">
            Delete account
          </Button>
          
          <Button size="large" onClick={(): Promise<void> => logout()} variant="outlined" color="secondary">
            Log-out
          </Button>
        </div>

      </div>
    </div>
  );
};

export default UserInfo;
