import Typography, { TypographyProps } from '@mui/material/Typography';
import styled from 'styled-components';
import { Box, Link } from '@mui/material';

import { AppVersionStore } from '../store/store';
import HideOnScroll from './hideOnScroll';

const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: '#3e3e3e;
  color: white;
  text-align: center;
  height: 60px;
`;

interface MyFooterProps {
  variant?: TypographyProps['variant'];
  align?: TypographyProps['align'];
  color?: TypographyProps['color'];
}

export default function MyFooter(props: MyFooterProps) {
  const version = AppVersionStore(state => state.version);
  return (
    <HideOnScroll>
      <StyledFooter component="footer">
        <Typography variant="body2" color="secondary" align="center" {...props}>
          {'Copyright © '}
          <Link color="inherit" href="https://artome.fi/">
            Artome Oy
          </Link>{' '}
          {new Date().getFullYear()}
          {' '}
          v.{version}
        </Typography>
      </StyledFooter>
    </HideOnScroll>
  );
}