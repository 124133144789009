import { createTheme } from "@mui/material/styles";

// Augment the palette to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    artome: Palette["primary"];
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    artome?: PaletteOptions["primary"];
    dark?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include custom colors
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    artome: true;
    dark: true;
  }
}

// Update the IconButton's color options to include custom colors
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    artome: true;
    dark: true;
  }
}

// Define custom breakpoints
declare module "@mui/system" {
  interface BreakpointOverrides {
    xs: false; // Remove the `xs` breakpoint
    sm: false; // Remove the `sm` breakpoint
    md: false; // Remove the `md` breakpoint
    lg: false; // Remove the `lg` breakpoint
    xl: false; // Remove the `xl` breakpoint
    laptop: true;
    tablet: true;
    mobile: true;
    desktop: true;
    ultraLarge: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      ultraLarge: 3000,
      desktop: 1280,
      laptop: 1024,
      tablet: 640,
      mobile: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
  },
  palette: {
    artome: {
      main: "#ECAA1D",
      light: "#ffffff",
      dark: "#000000",
    },
    primary: {
      main: "#ECAA1D",
    },
    secondary: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
    },
  },
});

export default theme;
