import React, { FC } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from 'swiper/modules';

import CaruouselItem from "./carouselItem";
import { ImageCarouselTypes } from "../models/types";

const ImageCarousel: FC<ImageCarouselTypes> = ({
  imagelist,
  onSlideChange,
  showRightButton,
  rightButtonPress,
  rightButtonTitle,
}) => {
  const height: number = window.screen.height - 500;

  const images: Array<any> = imagelist.map((d, i) => {
    return (
      <SwiperSlide key={i}>
        <CaruouselItem
          showButton={showRightButton}
          image={d.image}
          text={d.text}
          buttonTitle={rightButtonTitle}
          index={i + 1}
          height={height}
          buttonPress={rightButtonPress}
          link={d.link}
          linkText={d.linkText}
        />
      </SwiperSlide>
    );
  });

  return (
    <div className="carousel-container">
      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation]}
        spaceBetween={30}
        slidesPerView={1}
        onSlideChange={(swiper: any) => onSlideChange(swiper)}
      >
        {images}
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
