import React from "react";
import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import _x10SoftwareImageList from "../data/m10SoftwareInstallImages";
import TheHeader from "../components/theHeader";

function M10SoftwareSetup() {
  const [updateM10SoftwareState, m10SoftwareState] = InstallStateStore(
    (state) => [state.UpdateM10SoftwareState, state.m10SoftwareState]
  );
  const navigate = useNavigate();

  function slideChange(swiper: any) {
    if (swiper.activeIndex === _x10SoftwareImageList.length - 1) {
      updateM10SoftwareState(true);
    } else {
      updateM10SoftwareState(false);
    }
  }

  function buttonPress() {
    navigate("/m10-status");
  }

  return (
    <>
      <TheHeader
        headerText={"Setup the M10"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/m10-setup-legacy")}
      />
      <ImageCarousel
        imagelist={_x10SoftwareImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={m10SoftwareState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Next"
      />
    </>
  );
}

export default M10SoftwareSetup;
